import logoDefault from '@/assets/img/logo.svg';
import logoStacked from '@/assets/img/logo-stacked.svg';
import logoWhiteDefault from '@/assets/img/logo-white.svg';
import logoWhiteStacked from '@/assets/img/logo-white-stacked.svg';
import {
  GridOutline,
  HomeOutline,
  ImagesOutline,
  ListOutline,
  MailOutline,
  PeopleOutline,
  PersonOutline,
  SettingsOutline,
  ChatbubbleEllipsesOutline,
  CalendarOutline,
  DocumentTextOutline,
  FilterOutline,
  StarOutline,
  ChatbubblesOutline
} from '@vicons/ionicons5';
import AdsIcon from '@/components/icons/AdsIcon.vue';

export const CONSTANTS_COMPANY = {
  name: 'Listings to Leads',
  contact: {
    phone: '888-508-8322',
    email: 'support@listingstoleads.com',
    address: '9580 Oak Ave. Parkway, Ste. 7-127, Folsom, CA 95630'
  },
  social: {
    facebook: 'https://www.facebook.com/listingstoleads',
    twitter: 'https://twitter.com/ListingstoLeads',
    instagram: 'https://www.instagram.com/listingstoleads',
    linkedin: 'https://www.linkedin.com/company/listings-to-leads',
    youtube: 'https://www.youtube.com/user/MrListingstoLeads'
  },
  founders: [
    {
      name: 'Scott Pierce',
      role: 'Founder & CEO',
      photo: 'https://www.listingstoleads.com/asset-new2/img/scott.jpg',
      email: 'scott@listingstoleads.com'
    },
    {
      name: 'Vincent Meza',
      role: 'Vice-President of Sales',
      photo: 'https://www.listingstoleads.com/asset-new2/img/vince.jpg',
      email: 'vince@listingstoleads.com'
    }
  ],
  logos: {
    default: logoDefault,
    stacked: logoStacked,
    whiteDefault: logoWhiteDefault,
    whiteStacked: logoWhiteStacked
  }
};

export const CONSTANTS_URL = {
  baseUrl: import.meta.env.VITE_URL || 'https://api.listingstoleads.xyz/',
  baseApiUrl: import.meta.env.VITE_API_URL || 'https://api.listingstoleads.xyz/api/',
  mediaUrl: import.meta.env.VITE_MEDIA_URL || 'https://api.listingstoleads.xyz/media/',
  mediaPreviewUrl: import.meta.env.VITE_MEDIA_PREVIEW_URL || 'https://api.listingstoleads.xyz/media/preview/',
  templatesUrl: 'https://api.listingstoleads.xyz/templates/',
  landingPageUrl: import.meta.env.VITE_LANDING_PAGE_BASE_URL || 'https://searchallproperties.xyz/'
};

export const CONSTANTS_BRAND_BACKROUND_TYPES = [
  { label: 'Dark', value: 'dark' },
  { label: 'Light', value: 'light' },
  { label: 'Auto', value: 'auto' },
  { label: 'Custom', value: 'custom' }
];

export const CONSTANTS_DEFAULT_BACKROUND_COLORS = {
  dark: {
    backgroundColor: '#1b1d20',
    backgroundColorInverse: '#ffffff'
  },
  light: {
    backgroundColor: '#ffffff',
    backgroundColorInverse: '#111111'
  }
};

export const CONSTANTS_BRAND_THEMES = [
  {
    name: 'Allen Tate',
    value: 'allen-tate',
    colors: {
      primary: '#8b0b04',
      secondary: '#56555c',
      tertiary: '#b3b5b8'
    },
    font: 'Lato',
    backgroundType: 'custom',
    backgroundColor: '#f6f1d4',
    backgroundColorInverse: '#111111'
  },
  {
    name: 'Berkshire Hathaway',
    value: 'berkshire-hathaway',
    colors: {
      primary: '#552448',
      secondary: '#000000',
      tertiary: '#EAE3D4'
    },
    font: 'Georgia',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'Century 21',
    value: 'century-21',
    colors: {
      primary: '#BEAF87',
      secondary: '#252526',
      tertiary: '#A19276'
    },
    font: 'Avenir Next',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'Coldwell Banker',
    value: 'coldwell-banker',
    colors: {
      primary: '#012169',
      secondary: '#012169',
      tertiary: '#63666A'
    },
    font: 'Avenir Next',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'Crye Leike',
    value: 'crye-leike',
    colors: {
      primary: '#cc0000',
      secondary: '#212529',
      tertiary: '#cc0000'
    },
    font: 'Nunito',
    backgroundType: 'custom',
    backgroundColor: '#ecf0f1',
    backgroundColorInverse: '#111111'
  },
  {
    name: 'Douglas Elliman',
    value: 'douglas-elliman',
    colors: {
      primary: '#009CBD',
      secondary: '#53565A',
      tertiary: '#009CBD'
    },
    font: 'Avenir Next LT Pro',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'Engel & Völkers',
    value: 'engel-volkers',
    colors: {
      primary: '#ff0000',
      secondary: '#ff0000',
      tertiary: '#444444'
    },
    font: 'Avenir Next',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'ERA',
    value: 'era',
    colors: {
      primary: '#C8102E',
      secondary: '#250E62',
      tertiary: '#920337'
    },
    font: 'Avenir Next',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'eXp Realty',
    value: 'exp-realty',
    colors: {
      primary: '#1b1e2a',
      secondary: '#F5821F',
      tertiary: '#58595a'
    },
    font: 'Roboto',
    backgroundType: 'dark',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.dark.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.dark.backgroundColorInverse
  },
  {
    name: 'Home Smart',
    value: 'home-smart',
    colors: {
      primary: '#ed1a3c',
      secondary: '#000000',
      tertiary: '#504E55'
    },
    font: 'proxima-nova',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'Howard Hanna',
    value: 'howard-hanna',
    colors: {
      primary: '#0c653a',
      secondary: '#fbce20',
      tertiary: '#0c653a'
    },
    font: 'Avenir Next',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'Keller Williams',
    value: 'keller-williams',
    colors: {
      primary: '#B40101',
      secondary: '#999999',
      tertiary: '#666666'
    },
    font: 'proxima-nova',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'MVP Realty',
    value: 'mvp-realty',
    colors: {
      primary: '#003350',
      secondary: '#000000',
      tertiary: '#59585e'
    },
    font: 'Helvetica Neue',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'Pearson Smith Realty',
    value: 'pearson-smith-realty',
    colors: {
      primary: '#18b4c9',
      secondary: '#23282d',
      tertiary: '#23282d'
    },
    font: 'Avenir Next',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'Realty One',
    value: 'realty-one',
    colors: {
      primary: '#C5A95E',
      secondary: '#000000',
      tertiary: '#777777'
    },
    font: 'Avenir',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'RE/MAX',
    value: 'remax',
    colors: {
      primary: '#DC1C2E',
      secondary: '#003DA5',
      tertiary: '#B22B2E'
    },
    font: 'Avenir Next',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: "Sotheby's International",
    value: 'sothebys-international',
    colors: {
      primary: '#002349',
      secondary: '#666666',
      tertiary: '#999999'
    },
    font: 'Avenir Next',
    backgroundType: 'custom',
    backgroundColor: '#002349',
    backgroundColorInverse: '#ffffff'
  },
  {
    name: 'William Raveis',
    value: 'william-raveis',
    colors: {
      primary: '#5889b5',
      secondary: '#5D89B4',
      tertiary: '#41464b'
    },
    font: 'proxima-nova',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'Windermere',
    value: 'windermere',
    colors: {
      primary: '#002A4E',
      secondary: '#5D6468',
      tertiary: '#009cde'
    },
    font: 'Avenir Next',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'Default',
    value: 'default',
    colors: {
      primary: '#002349',
      secondary: '#B80D0D',
      tertiary: '#DEE2E6'
    },
    font: 'Avenir Next',
    backgroundType: 'light',
    backgroundColor: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColor,
    backgroundColorInverse: CONSTANTS_DEFAULT_BACKROUND_COLORS.light.backgroundColorInverse
  },
  {
    name: 'Custom',
    value: 'custom',
    colors: {
      primary: '#01295c',
      secondary: '#527db2',
      tertiary: '#002a5c'
    }
  }
];

export const CONSTANTS_BRAND_THEMES_FONTS = [
  { label: 'Default', value: 'system-ui' },
  { label: 'Avenir', value: 'Avenir' },
  { label: 'Avenir Next', value: 'Avenir Next' },
  { label: 'Avenir Next LT Pro', value: 'Avenir Next LT Pro' },
  { label: 'Futura Pt', value: 'futura-pt' },
  { label: 'Lato', value: 'Lato' },
  { label: 'Montserrat', value: 'Montserrat' },
  { label: 'Proxima Nova', value: 'proxima-nova' },
  { label: 'Georgia', value: 'Georgia' },
  { label: 'Nunito', value: 'Nunito' },
  { label: 'Roboto', value: 'Roboto' },
  { label: 'Helvetica Neue', value: 'Helvetica Neue' }
];

// SIDEBAR MAIN ROUTE
export const SIDEBAR_MAIN_ROUTES = [
  { name: 'GetStartedView', path: '/get-started', title: 'Get Started', icon: HomeOutline },
  { name: 'MarketingView', path: '/ads', title: 'Ads', icon: AdsIcon },
  { name: 'BotsView', path: '/bots', title: 'Bots', icon: ChatbubbleEllipsesOutline },
  { name: 'ListingsView', path: '/listings', title: 'Listings', icon: ListOutline },
  { name: 'PdfGuidesView', path: '/guides', title: 'PDF Guides', icon: DocumentTextOutline },
  { name: 'SocialView', path: '/social', title: 'Social Content', icon: CalendarOutline },
  { name: 'LandingPagesView', path: '/pages', title: 'Landing Pages', icon: GridOutline },
  { name: 'ReviewsView', path: '/reviews', title: 'Client Reviews', icon: StarOutline },
  { name: 'FollowUpsView', path: '/follow-ups', title: 'Follow Ups', icon: MailOutline },
  { name: 'TextIvrView', path: '/text-ivr', title: 'Text / IVR', icon: ChatbubblesOutline },
  { name: 'LeadsView', path: '/leads', title: 'Leads', icon: FilterOutline },
  { name: 'MediaLibraryView', path: '/media-library', title: 'Media Library', icon: ImagesOutline },
  { name: 'ProfileGeneralView', path: '/profile/general', title: 'Profile', icon: PersonOutline },
  { name: 'AccountPreferencesView', path: '/account/preferences', title: 'Account Settings', icon: SettingsOutline }
];
// SIDEBAR BROKER ROUTE
export const SIDEBAR_BROKER_ROUTES = [
  { name: 'BrokerAgentsView', path: '/broker/agents', title: 'Agents', icon: PeopleOutline },
  { name: 'BrokerOfficeSettingsView', path: '/broker/office-settings', title: 'Office Settings', icon: SettingsOutline }
];

// SUPPORTED TAGS AND NAMES FOR EMAILS TEMPLATES
export const SUPPORTED_EMAIL_TAGS = ['{signature}'];
export const SUPPORTED_LANDING_PAGE_TAGS = ['{landing_page_url}', '{landing_page_city}', '{landing_page_name}'];
export const SUPPORTED_LANDING_PAGES_TAGS = ['{free_home_value_link}', '{new_construction_link}', '{downpayment_assistance_link}'];
export const SUPPORTED_AGENT_TAGS = [
  '{agent_first_name}',
  '{agent_last_name}',
  '{agent_full_name}',
  '{agent_username}',
  '{agent_company}',
  '{agent_email}',
  '{agent_phone}'
];
export const SUPPORTED_LEADS_TAGS = ['{lead_first_name}', '{lead_last_name}', '{lead_full_name}'];
export const SUPPORTED_LISTING_TAGS = [
  '{listing_address}',
  '{listing_street}',
  '{listing_city}',
  '{listing_state}',
  '{listing_zip_code}',
  '{listing_status}',
  '{listing_link}',
  '{listing_price}',
  '{listing_headline}',
  '{listing_description}',
  '{listing_bedrooms}',
  '{listing_bathrooms}',
  '{listing_living_area}',
  '{listing_image}',
  '{listing_social_share_image}'
];
export const SUPPORTED_GUIDE_TAGS = ['{report_name}', '{report_link}'];

export const SUPPORTED_CURRENCIES = [
  { label: '$ - United States Dollars', value: 'USD' },
  { label: '€ - Euro', value: 'EUR' },
  { label: '£ - United Kingdom Pounds', value: 'GBP' },
  { label: 'A$ - Australia Dollars', value: 'AUD' },
  { label: 'C$ - Canada Dollars', value: 'CAD' },
  { label: 'NZ$ - New Zealand Dollars', value: 'NZD' },
  { label: 'R - South African RAND', value: 'ZAR' },
  { label: 'CI$ - Cayman Islands', value: 'KYD' },
  { label: '฿ - Thai Baht', value: 'TB' }
];

export const SUPPORTED_TIMEZONES = [
  { label: 'GMT', value: 'GMT' },
  { label: 'Central Time Zone', value: 'Central Time Zone' },
  { label: 'Eastern Time Zone', value: 'Eastern Time Zone' },
  { label: 'Pacific Time Zone', value: 'Pacific Time Zone' },
  { label: 'Newfoundland Time Zone', value: 'Newfoundland Time Zone' },
  { label: 'Atlantic Time Zone', value: 'Atlantic Time Zone' },
  { label: 'Mountain Time', value: 'Mountain Time' },
  { label: 'Hawaiian Time', value: 'Hawaiian Time' },
  { label: 'Alaskan Time', value: 'Alaskan Time' },
  { label: 'New Zealand Standard Time Zone', value: 'New Zealand Standard Time Zone' },
  { label: 'Australian Eastern Standard Time', value: 'Australian Eastern Standard Time' },
  { label: 'Australian Central Standard Time', value: 'Australian Central Standard Time' },
  { label: 'Eastern European Time Zone', value: 'Eastern European Time Zone' },
  { label: 'Western European Time Zone', value: 'Western European Time Zone' }
];

export const SUPPORTED_LANGUAGES = [
  { label: 'English', value: 'en' },
  { label: 'Spanish', value: 'es' }
];

export const SUPPORTED_DOMAINS = {
  main: [{ label: 'searchallproperties.xyz', value: 'searchallproperties.xyz' }],
  listings: [{ label: 'thebestlisting.xyz', value: 'thebestlisting.xyz' }],
  reviews: [{ label: 'bestclientreviews.xyz', value: 'bestclientreviews.xyz' }],
  bio: [{ label: 'seebio.site', value: 'seebio.site' }],
  headers: [{ label: 'searchallproperties.xyz', value: 'searchallproperties.xyz' }],
  freeHomeValue: [{ label: 'freehomevalues.xyz', value: 'freehomevalues.xyz' }]
};

export const HOME_ROUTE_NAME = 'GetStartedView';
export const REACTIVATE_ACCOUNT_ROUTE_NAME = 'ReactivateAccountView'; // page to reactivate account
export const REACTIVATE_ACCOUNT_AGENT_ROUTE_NAME = 'ReactivateAccountAgentView'; // page to reactivate account for agents
export const SIGNUP_COMPLETE_ROUTE_NAME = 'SignupCompleteView'; // page to complete the signup process
export const SIGNUP_COMPLETE_AGENT_ROUTE_NAME = 'SignupCompleteAgentView'; // page to complete the signup process for agents

export const SEARCH_ALL_PROPERTIES_DNS = 'dns.searchallproperties.xyz';

export const URL_PREFIX = 'https://'; // this will be used to have a unified way to build the urls ex. domains and lead links
export const URL_REGEX = /((http|https):\/\/[^\s?]+)/g; // ex https://www.example.com/post?query=1

export const DRAGGABLE_OPTIONS = {
  animation: 250,
  group: 'people',
  disabled: false,
  ghostClass: 'draggable-ghost'
};

// These script tools are used to make sure the values & labels are consistent across the app in all script tools
export const SCRIPT_TOOLS_OPTIONS = {
  listings: {
    label: 'Listings',
    value: 'listings'
  },
  landingPages: {
    label: 'Landing Pages',
    value: 'landing_pages'
  },
  listingPresentation: {
    label: 'Listing Presentation',
    value: 'listing_presentation'
  },
  clientReviewWebsite: {
    label: 'Client Review Website',
    value: 'review'
  }
};
export const SCRIPT_TYPES_OPTIONS = {
  shortFormVideo: {
    label: 'Short Form Video',
    value: 'short_form_video'
  },
  circleProspecting: {
    label: 'Circle Prospecting',
    value: 'circle_prospecting'
  },
  databaseCall: {
    label: 'Database Call',
    value: 'database_call'
  },
  databaseColdCalling: {
    label: 'Database/Cold Calling',
    value: 'database_cold_calling'
  },
  farmCircleProspecting: {
    label: 'Farm/Circle Prospecting',
    value: 'farm_circle_prospecting'
  }
};
export const BILLING_PACKAGES_OPTIONS_NEW = {
  ivrPackage: {
    label: 'Text/IVR',
    value: 'text_ivr_package'
  },
  googleAds: {
    label: 'Google Ads',
    value: 'google_ads'
  },
  aiTextFollowUp: {
    label: 'AI Text Follow Ups',
    value: 'ai_text_follow_ups'
  },
  partialToFullLead: {
    label: 'Partial to Full Lead Converter',
    value: 'partial_to_full_leads'
  },
  recruitingLandingPages: {
    label: 'Recruiting Landing Pages',
    value: 'recruiting_pages'
  }
};

export const ACCOUNT_RESTORE_DEADLINE = 7; // days

export const SUPPORTED_CANCELLATION_REASONS = [
  // 'no_longer_needed' 'too_expensive' 'found_better_service' 'difficult_to_use' 'lack_of_features' 'technical_issues' 'poor_customer_service' 'other'
  { label: 'No longer needed', value: 'no_longer_needed' },
  { label: 'Too expensive', value: 'too_expensive' },
  { label: 'Found better service', value: 'found_better_service' },
  { label: 'Difficult to use', value: 'difficult_to_use' },
  { label: 'Lack of features', value: 'lack_of_features' },
  { label: 'Technical issues', value: 'technical_issues' },
  { label: 'Poor customer service', value: 'poor_customer_service' },
  { label: 'Other', value: 'other' }
];

export const TESTIMONIALS_OPTIONS = [
  {
    testimonial: `Not only does it enhance my local presence credibility with my friends and my past present clients, it helps me to build new followers prospective buyers sellers. It's a service that is reasonably priced affordable and the support is top notch!  I highly recommend it!`
  },
  {
    testimonial: `We get an average of 2 listings for every listing we market using Listings-to-Leads! Trying to get that number to 3 listings this year!`
  },
  {
    testimonial: `As you all know, I use L2L for ALL of my marketing....Coming Soon, Just Listed, Open House and Just Sold along with some other landing pages. I'm still amazed at the amount of leads I get. See the attached 3 ads I have run the past 2 weeks. This has produced 89 leads in to my CRM with I am retargeting with Facebook Ads. Of those 89....I am currently working with 3 of them as active buyers. Total Spend = $120 on the ads. Target the right people and use Facebook Registration after 5 seconds for coming soon, 10 seconds for Just Listed and the leads will come`
  },
  {
    testimonial: `ListingstoLeads is an invaluable resource in my real estate business that we primarily use to feature our new listings and open houses on Facebook.  I just took a look at my analytics and in the past 9 months it has generated 846 leads for me!  WOW!  That is 21.15 leads per week!  For the minimal expense of this great service, I'd never give it up!`
  },
  {
    testimonial: `Goal for July is generate at least 150 buyer leads to keep my team busy-The outcome 220 leads..all under $400.00. Thanks L2L!`
  },
  {
    testimonial: `This product rocks. Love, love listings to leads. Whether it is lead generation, marketing my properties, to training new agents, I would be at a loss without this product. Sorry can't give you the bad or the ugly because I have no complaints. And customer support is far above the best.`
  },
  {
    testimonial: `Thank you, Listing-to-Leads! I emailed a Just Listed flyer to my database early this afternoon. As of my last email check, I had 3 buyers request information about a specific property type (i.e. Foreclosure homes). These buyers were already in my database but not actively looking.`
  },
  {
    testimonial: `Hi Scott, Just fyi that I'm doing exactly what you said on the last webinar and I have been inundated with a tsunami of leads!`
  },
  {
    testimonial: `I literally dislike "services" who sell themselves to agents as something "fantastic". Listings To Leads is one of the finest examples of a company who not only does what they say, but provides an absolutely fantastic service at a very fair price , probably more fair than it should be!`
  },
  {
    testimonial: `Scott Pierce I so admire your patience and tolerance for a product that is worth at least 150% of the price you are charging... the reason I am in business...and the reason I am on track to do 200K in my 2nd year in real real estate. Cheers L2L!`
  }
];

export const DEFAULT_SELECTED_COUNTRY = {
  name: 'United States',
  label: 'United States',
  value: 'US',
  code: 'US',
  emoji: '🇺🇸',
  image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/us.svg',
  dialCodes: ['+1'],
  slug: 'united-states'
};
// country options
export const SUPPORTED_COUNTRIES = [
  {
    name: 'Afghanistan',
    label: 'Afghanistan',
    value: 'AF',
    code: 'AF',
    emoji: '🇦🇫',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/af.svg',
    dialCodes: ['+93'],
    slug: 'afghanistan'
  },
  {
    name: 'Albania',
    label: 'Albania',
    value: 'AL',
    code: 'AL',
    emoji: '🇦🇱',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/al.svg',
    dialCodes: ['+355'],
    slug: 'albania'
  },
  {
    name: 'Andorra',
    label: 'Andorra',
    value: 'AD',
    code: 'AD',
    emoji: '🇦🇩',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ad.svg',
    dialCodes: ['+376'],
    slug: 'andorra'
  },
  {
    name: 'Angola',
    label: 'Angola',
    value: 'AO',
    code: 'AO',
    emoji: '🇦🇴',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ao.svg',
    dialCodes: ['+244'],
    slug: 'angola'
  },
  {
    name: 'Anguilla',
    label: 'Anguilla',
    value: 'AI',
    code: 'AI',
    emoji: '🇦🇮',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ai.svg',
    dialCodes: ['+1264'],
    slug: 'anguilla'
  },
  {
    name: 'Antarctica',
    label: 'Antarctica',
    value: 'AQ',
    code: 'AQ',
    emoji: '🇦🇶',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/aq.svg',
    dialCodes: ['+672'],
    slug: 'antarctica'
  },
  {
    name: 'Antigua & Barbuda',
    label: 'Antigua & Barbuda',
    value: 'AG',
    code: 'AG',
    emoji: '🇦🇬',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ag.svg',
    dialCodes: ['+1268'],
    slug: 'antigua-and-barbuda'
  },
  {
    name: 'Argentina',
    label: 'Argentina',
    value: 'AR',
    code: 'AR',
    emoji: '🇦🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ar.svg',
    dialCodes: ['+54'],
    slug: 'argentina'
  },
  {
    name: 'Armenia',
    label: 'Armenia',
    value: 'AM',
    code: 'AM',
    emoji: '🇦🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/am.svg',
    dialCodes: ['+374'],
    slug: 'armenia'
  },
  {
    name: 'Aruba',
    label: 'Aruba',
    value: 'AW',
    code: 'AW',
    emoji: '🇦🇼',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/aw.svg',
    dialCodes: ['+297'],
    slug: 'aruba'
  },
  {
    name: 'Ascension Island',
    label: 'Ascension Island',
    value: 'AC',
    code: 'AC',
    emoji: '🇦🇨',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ac.svg',
    slug: 'ascension-island'
  },
  {
    name: 'Australia',
    label: 'Australia',
    value: 'AU',
    code: 'AU',
    emoji: '🇦🇺',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/au.svg',
    dialCodes: ['+61'],
    slug: 'australia'
  },
  {
    name: 'Austria',
    label: 'Austria',
    value: 'AT',
    code: 'AT',
    emoji: '🇦🇹',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/at.svg',
    dialCodes: ['+43'],
    slug: 'austria'
  },
  {
    name: 'Azerbaijan',
    label: 'Azerbaijan',
    value: 'AZ',
    code: 'AZ',
    emoji: '🇦🇿',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/az.svg',
    dialCodes: ['+994'],
    slug: 'azerbaijan'
  },
  {
    name: 'Bahamas',
    label: 'Bahamas',
    value: 'BS',
    code: 'BS',
    emoji: '🇧🇸',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bs.svg',
    dialCodes: ['+1242'],
    slug: 'bahamas'
  },
  {
    name: 'Bahrain',
    label: 'Bahrain',
    value: 'BH',
    code: 'BH',
    emoji: '🇧🇭',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bh.svg',
    dialCodes: ['+973'],
    slug: 'bahrain'
  },
  {
    name: 'Bangladesh',
    label: 'Bangladesh',
    value: 'BD',
    code: 'BD',
    emoji: '🇧🇩',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bd.svg',
    dialCodes: ['+880'],
    slug: 'bangladesh'
  },
  {
    name: 'Barbados',
    label: 'Barbados',
    value: 'BB',
    code: 'BB',
    emoji: '🇧🇧',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bb.svg',
    dialCodes: ['+1246'],
    slug: 'barbados'
  },
  {
    name: 'Belarus',
    label: 'Belarus',
    value: 'BY',
    code: 'BY',
    emoji: '🇧🇾',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/by.svg',
    dialCodes: ['+375'],
    slug: 'belarus'
  },
  {
    name: 'Belgium',
    label: 'Belgium',
    value: 'BE',
    code: 'BE',
    emoji: '🇧🇪',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/be.svg',
    dialCodes: ['+32'],
    slug: 'belgium'
  },
  {
    name: 'Belize',
    label: 'Belize',
    value: 'BZ',
    code: 'BZ',
    emoji: '🇧🇿',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bz.svg',
    dialCodes: ['+501'],
    slug: 'belize'
  },
  {
    name: 'Benin',
    label: 'Benin',
    value: 'BJ',
    code: 'BJ',
    emoji: '🇧🇯',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bj.svg',
    dialCodes: ['+229'],
    slug: 'benin'
  },
  {
    name: 'Bermuda',
    label: 'Bermuda',
    value: 'BM',
    code: 'BM',
    emoji: '🇧🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bm.svg',
    dialCodes: ['+1441'],
    slug: 'bermuda'
  },
  {
    name: 'Bhutan',
    label: 'Bhutan',
    value: 'BT',
    code: 'BT',
    emoji: '🇧🇹',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bt.svg',
    dialCodes: ['+975'],
    slug: 'bhutan'
  },
  {
    name: 'Bolivia',
    label: 'Bolivia',
    value: 'BO',
    code: 'BO',
    emoji: '🇧🇴',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bo.svg',
    dialCodes: ['+591'],
    slug: 'bolivia'
  },
  {
    name: 'Bosnia & Herzegovina',
    label: 'Bosnia & Herzegovina',
    value: 'BA',
    code: 'BA',
    emoji: '🇧🇦',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ba.svg',
    dialCodes: ['+387'],
    slug: 'bosnia-and-herzegovina'
  },
  {
    name: 'Botswana',
    label: 'Botswana',
    value: 'BW',
    code: 'BW',
    emoji: '🇧🇼',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bw.svg',
    dialCodes: ['+267'],
    slug: 'botswana'
  },
  {
    name: 'Brazil',
    label: 'Brazil',
    value: 'BR',
    code: 'BR',
    emoji: '🇧🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/br.svg',
    dialCodes: ['+55'],
    slug: 'brazil'
  },
  {
    name: 'British Indian Ocean Territory',
    label: 'British Indian Ocean Territory',
    value: 'IO',
    code: 'IO',
    emoji: '🇮🇴',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/io.svg',
    slug: 'british-indian-ocean-territory'
  },
  {
    name: 'Brunei',
    label: 'Brunei',
    value: 'BN',
    code: 'BN',
    emoji: '🇧🇳',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bn.svg',
    dialCodes: ['+673'],
    slug: 'brunei'
  },
  {
    name: 'Bulgaria',
    label: 'Bulgaria',
    value: 'BG',
    code: 'BG',
    emoji: '🇧🇬',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bg.svg',
    dialCodes: ['+359'],
    slug: 'bulgaria'
  },
  {
    name: 'Burkina Faso',
    label: 'Burkina Faso',
    value: 'BF',
    code: 'BF',
    emoji: '🇧🇫',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bf.svg',
    dialCodes: ['+226'],
    slug: 'burkina-faso'
  },
  {
    name: 'Burundi',
    label: 'Burundi',
    value: 'BI',
    code: 'BI',
    emoji: '🇧🇮',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bi.svg',
    dialCodes: ['+257'],
    slug: 'burundi'
  },
  {
    name: 'Cabo Verde',
    label: 'Cabo Verde',
    value: 'CV',
    code: 'CV',
    emoji: '🇨🇻',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cv.svg',
    dialCodes: ['+238'],
    slug: 'cabo-verde'
  },
  {
    name: 'Cambodia',
    label: 'Cambodia',
    value: 'KH',
    code: 'KH',
    emoji: '🇰🇭',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/kh.svg',
    dialCodes: ['+855'],
    slug: 'cambodia'
  },
  {
    name: 'Cameroon',
    label: 'Cameroon',
    value: 'CM',
    code: 'CM',
    emoji: '🇨🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cm.svg',
    dialCodes: ['+237'],
    slug: 'cameroon'
  },
  {
    name: 'Canada',
    label: 'Canada',
    value: 'CA',
    code: 'CA',
    emoji: '🇨🇦',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ca.svg',
    dialCodes: ['+1'],
    slug: 'canada'
  },
  {
    name: 'Cayman Islands',
    label: 'Cayman Islands',
    value: 'KY',
    code: 'KY',
    emoji: '🇰🇾',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ky.svg',
    dialCodes: ['+1345'],
    slug: 'cayman-islands'
  },
  {
    name: 'Central African Republic',
    label: 'Central African Republic',
    value: 'CF',
    code: 'CF',
    emoji: '🇨🇫',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cf.svg',
    dialCodes: ['+236'],
    slug: 'central-african-republic'
  },
  {
    name: 'Chad',
    label: 'Chad',
    value: 'TD',
    code: 'TD',
    emoji: '🇹🇩',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/td.svg',
    dialCodes: ['+235'],
    slug: 'chad'
  },
  {
    name: 'Chile',
    label: 'Chile',
    value: 'CL',
    code: 'CL',
    emoji: '🇨🇱',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cl.svg',
    dialCodes: ['+56'],
    slug: 'chile'
  },
  {
    name: 'China',
    label: 'China',
    value: 'CN',
    code: 'CN',
    emoji: '🇨🇳',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cn.svg',
    dialCodes: ['+86'],
    slug: 'china'
  },
  {
    name: 'Christmas Island',
    label: 'Christmas Island',
    value: 'CX',
    code: 'CX',
    emoji: '🇨🇽',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cx.svg',
    slug: 'christmas-island'
  },
  {
    name: 'Cocos (Keeling) Islands',
    label: 'Cocos (Keeling) Islands',
    value: 'CC',
    code: 'CC',
    emoji: '🇨🇨',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cc.svg',
    slug: 'cocos-(keeling)-islands'
  },
  {
    name: 'Colombia',
    label: 'Colombia',
    value: 'CO',
    code: 'CO',
    emoji: '🇨🇴',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/co.svg',
    dialCodes: ['+57'],
    slug: 'colombia'
  },
  {
    name: 'Comoros',
    label: 'Comoros',
    value: 'KM',
    code: 'KM',
    emoji: '🇰🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/km.svg',
    dialCodes: ['+269'],
    slug: 'comoros'
  },
  {
    name: 'Congo - Brazzaville',
    label: 'Congo - Brazzaville',
    value: 'CG',
    code: 'CG',
    emoji: '🇨🇬',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cg.svg',
    dialCodes: ['+242'],
    slug: 'congo-brazzaville'
  },
  {
    name: 'Congo - Kinshasa',
    label: 'Congo - Kinshasa',
    value: 'CD',
    code: 'CD',
    emoji: '🇨🇩',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cd.svg',
    dialCodes: ['+243'],
    slug: 'congo-kinshasa'
  },
  {
    name: 'Cook Islands',
    label: 'Cook Islands',
    value: 'CK',
    code: 'CK',
    emoji: '🇨🇰',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ck.svg',
    dialCodes: ['+682'],
    slug: 'cook-islands'
  },
  {
    name: 'Costa Rica',
    label: 'Costa Rica',
    value: 'CR',
    code: 'CR',
    emoji: '🇨🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cr.svg',
    dialCodes: ['+506'],
    slug: 'costa-rica'
  },
  {
    name: 'Croatia',
    label: 'Croatia',
    value: 'HR',
    code: 'HR',
    emoji: '🇭🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/hr.svg',
    dialCodes: ['+385'],
    slug: 'croatia'
  },
  {
    name: 'Cuba',
    label: 'Cuba',
    value: 'CU',
    code: 'CU',
    emoji: '🇨🇺',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cu.svg',
    dialCodes: ['+53'],
    slug: 'cuba'
  },
  {
    name: 'Curaçao',
    label: 'Curaçao',
    value: 'CW',
    code: 'CW',
    emoji: '🇨🇼',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cw.svg',
    dialCodes: ['+599'],
    slug: 'curacao'
  },
  {
    name: 'Cyprus',
    label: 'Cyprus',
    value: 'CY',
    code: 'CY',
    emoji: '🇨🇾',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cy.svg',
    dialCodes: ['+357'],
    slug: 'cyprus'
  },
  {
    name: 'Czechia',
    label: 'Czechia',
    value: 'CZ',
    code: 'CZ',
    emoji: '🇨🇿',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/cz.svg',
    dialCodes: ['+420'],
    slug: 'czechia'
  },
  {
    name: 'Côte d’Ivoire',
    label: 'Côte d’Ivoire',
    value: 'CI',
    code: 'CI',
    emoji: '🇨🇮',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ci.svg',
    dialCodes: ['+225'],
    slug: "cote-d'ivoire"
  },
  {
    name: 'Denmark',
    label: 'Denmark',
    value: 'DK',
    code: 'DK',
    emoji: '🇩🇰',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/dk.svg',
    dialCodes: ['+45'],
    slug: 'denmark'
  },
  {
    name: 'Djibouti',
    label: 'Djibouti',
    value: 'DJ',
    code: 'DJ',
    emoji: '🇩🇯',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/dj.svg',
    dialCodes: ['+253'],
    slug: 'djibouti'
  },
  {
    name: 'Dominica',
    label: 'Dominica',
    value: 'DM',
    code: 'DM',
    emoji: '🇩🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/dm.svg',
    dialCodes: ['+1767'],
    slug: 'dominica'
  },
  {
    name: 'Dominican Republic',
    label: 'Dominican Republic',
    value: 'DO',
    code: 'DO',
    emoji: '🇩🇴',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/do.svg',
    dialCodes: ['+1 809', '+1 829', '+1 849'],
    slug: 'dominican-republic'
  },
  {
    name: 'Ecuador',
    label: 'Ecuador',
    value: 'EC',
    code: 'EC',
    emoji: '🇪🇨',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ec.svg',
    dialCodes: ['+593'],
    slug: 'ecuador'
  },
  {
    name: 'Egypt',
    label: 'Egypt',
    value: 'EG',
    code: 'EG',
    emoji: '🇪🇬',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/eg.svg',
    dialCodes: ['+20'],
    slug: 'egypt'
  },
  {
    name: 'El Salvador',
    label: 'El Salvador',
    value: 'SV',
    code: 'SV',
    emoji: '🇸🇻',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sv.svg',
    dialCodes: ['+503'],
    slug: 'el-salvador'
  },
  {
    name: 'Equatorial Guinea',
    label: 'Equatorial Guinea',
    value: 'GQ',
    code: 'GQ',
    emoji: '🇬🇶',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gq.svg',
    dialCodes: ['+240'],
    slug: 'equatorial-guinea'
  },
  {
    name: 'Eritrea',
    label: 'Eritrea',
    value: 'ER',
    code: 'ER',
    emoji: '🇪🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/er.svg',
    dialCodes: ['+291'],
    slug: 'eritrea'
  },
  {
    name: 'Estonia',
    label: 'Estonia',
    value: 'EE',
    code: 'EE',
    emoji: '🇪🇪',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ee.svg',
    dialCodes: ['+372'],
    slug: 'estonia'
  },
  {
    name: 'Eswatini',
    label: 'Eswatini',
    value: 'SZ',
    code: 'SZ',
    emoji: '🇸🇿',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sz.svg',
    dialCodes: ['+268'],
    slug: 'eswatini'
  },
  {
    name: 'Ethiopia',
    label: 'Ethiopia',
    value: 'ET',
    code: 'ET',
    emoji: '🇪🇹',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/et.svg',
    dialCodes: ['+251'],
    slug: 'ethiopia'
  },
  {
    name: 'Falkland Islands',
    label: 'Falkland Islands',
    value: 'FK',
    code: 'FK',
    emoji: '🇫🇰',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/fk.svg',
    dialCodes: ['+500'],
    slug: 'falkland-islands'
  },
  {
    name: 'Faroe Islands',
    label: 'Faroe Islands',
    value: 'FO',
    code: 'FO',
    emoji: '🇫🇴',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/fo.svg',
    dialCodes: ['+298'],
    slug: 'faroe-islands'
  },
  {
    name: 'Fiji',
    label: 'Fiji',
    value: 'FJ',
    code: 'FJ',
    emoji: '🇫🇯',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/fj.svg',
    dialCodes: ['+679'],
    slug: 'fiji'
  },
  {
    name: 'Finland',
    label: 'Finland',
    value: 'FI',
    code: 'FI',
    emoji: '🇫🇮',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/fi.svg',
    dialCodes: ['+358'],
    slug: 'finland'
  },
  {
    name: 'France',
    label: 'France',
    value: 'FR',
    code: 'FR',
    emoji: '🇫🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/fr.svg',
    dialCodes: ['+33'],
    slug: 'france'
  },
  {
    name: 'French Guiana',
    label: 'French Guiana',
    value: 'GF',
    code: 'GF',
    emoji: '🇬🇫',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gf.svg',
    dialCodes: ['+594'],
    slug: 'french-guiana'
  },
  {
    name: 'French Polynesia',
    label: 'French Polynesia',
    value: 'PF',
    code: 'PF',
    emoji: '🇵🇫',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pf.svg',
    dialCodes: ['+689'],
    slug: 'french-polynesia'
  },
  {
    name: 'French Southern Territories',
    label: 'French Southern Territories',
    value: 'TF',
    code: 'TF',
    emoji: '🇹🇫',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tf.svg',
    slug: 'french-southern-territories'
  },
  {
    name: 'Gabon',
    label: 'Gabon',
    value: 'GA',
    code: 'GA',
    emoji: '🇬🇦',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ga.svg',
    dialCodes: ['+241'],
    slug: 'gabon'
  },
  {
    name: 'Gambia',
    label: 'Gambia',
    value: 'GM',
    code: 'GM',
    emoji: '🇬🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gm.svg',
    dialCodes: ['+220'],
    slug: 'gambia'
  },
  {
    name: 'Georgia',
    label: 'Georgia',
    value: 'GE',
    code: 'GE',
    emoji: '🇬🇪',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ge.svg',
    dialCodes: ['+995'],
    slug: 'georgia'
  },
  {
    name: 'Germany',
    label: 'Germany',
    value: 'DE',
    code: 'DE',
    emoji: '🇩🇪',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/de.svg',
    dialCodes: ['+49'],
    slug: 'germany'
  },
  {
    name: 'Ghana',
    label: 'Ghana',
    value: 'GH',
    code: 'GH',
    emoji: '🇬🇭',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gh.svg',
    dialCodes: ['+233'],
    slug: 'ghana'
  },
  {
    name: 'Gibraltar',
    label: 'Gibraltar',
    value: 'GI',
    code: 'GI',
    emoji: '🇬🇮',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gi.svg',
    dialCodes: ['+350'],
    slug: 'gibraltar'
  },
  {
    name: 'Greece',
    label: 'Greece',
    value: 'GR',
    code: 'GR',
    emoji: '🇬🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gr.svg',
    dialCodes: ['+30'],
    slug: 'greece'
  },
  {
    name: 'Greenland',
    label: 'Greenland',
    value: 'GL',
    code: 'GL',
    emoji: '🇬🇱',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gl.svg',
    dialCodes: ['+299'],
    slug: 'greenland'
  },
  {
    name: 'Grenada',
    label: 'Grenada',
    value: 'GD',
    code: 'GD',
    emoji: '🇬🇩',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gd.svg',
    dialCodes: ['+1473'],
    slug: 'grenada'
  },
  {
    name: 'Guadeloupe',
    label: 'Guadeloupe',
    value: 'GP',
    code: 'GP',
    emoji: '🇬🇵',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gp.svg',
    dialCodes: ['+590'],
    slug: 'guadeloupe'
  },
  {
    name: 'Guam',
    label: 'Guam',
    value: 'GU',
    code: 'GU',
    emoji: '🇬🇺',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gu.svg',
    dialCodes: ['+1671'],
    slug: 'guam'
  },
  {
    name: 'Guatemala',
    label: 'Guatemala',
    value: 'GT',
    code: 'GT',
    emoji: '🇬🇹',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gt.svg',
    dialCodes: ['+502'],
    slug: 'guatemala'
  },
  {
    name: 'Guernsey',
    label: 'Guernsey',
    value: 'GG',
    code: 'GG',
    emoji: '🇬🇬',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gg.svg',
    dialCodes: ['+44'],
    slug: 'guernsey'
  },
  {
    name: 'Guinea',
    label: 'Guinea',
    value: 'GN',
    code: 'GN',
    emoji: '🇬🇳',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gn.svg',
    dialCodes: ['+224'],
    slug: 'guinea'
  },
  {
    name: 'Guinea-Bissau',
    label: 'Guinea-Bissau',
    value: 'GW',
    code: 'GW',
    emoji: '🇬🇼',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gw.svg',
    dialCodes: ['+245'],
    slug: 'guinea-bissau'
  },
  {
    name: 'Guyana',
    label: 'Guyana',
    value: 'GY',
    code: 'GY',
    emoji: '🇬🇾',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gy.svg',
    dialCodes: ['+592'],
    slug: 'guyana'
  },
  {
    name: 'Haiti',
    label: 'Haiti',
    value: 'HT',
    code: 'HT',
    emoji: '🇭🇹',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ht.svg',
    dialCodes: ['+509'],
    slug: 'haiti'
  },
  {
    name: 'Heard & McDonald Islands',
    label: 'Heard & McDonald Islands',
    value: 'HM',
    code: 'HM',
    emoji: '🇭🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/hm.svg',
    slug: 'heard-and-mcdonald-islands'
  },
  {
    name: 'Honduras',
    label: 'Honduras',
    value: 'HN',
    code: 'HN',
    emoji: '🇭🇳',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/hn.svg',
    dialCodes: ['+504'],
    slug: 'honduras'
  },
  {
    name: 'Hong Kong SAR China',
    label: 'Hong Kong SAR China',
    value: 'HK',
    code: 'HK',
    emoji: '🇭🇰',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/hk.svg',
    dialCodes: ['+852'],
    slug: 'hong-kong-sar-china'
  },
  {
    name: 'Hungary',
    label: 'Hungary',
    value: 'HU',
    code: 'HU',
    emoji: '🇭🇺',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/hu.svg',
    dialCodes: ['+36'],
    slug: 'hungary'
  },
  {
    name: 'Iceland',
    label: 'Iceland',
    value: 'IS',
    code: 'IS',
    emoji: '🇮🇸',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/is.svg',
    dialCodes: ['+354'],
    slug: 'iceland'
  },
  {
    name: 'India',
    label: 'India',
    value: 'IN',
    code: 'IN',
    emoji: '🇮🇳',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/in.svg',
    dialCodes: ['+91'],
    slug: 'india'
  },
  {
    name: 'Indonesia',
    label: 'Indonesia',
    value: 'ID',
    code: 'ID',
    emoji: '🇮🇩',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/id.svg',
    dialCodes: ['+62'],
    slug: 'indonesia'
  },
  {
    name: 'Iran',
    label: 'Iran',
    value: 'IR',
    code: 'IR',
    emoji: '🇮🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ir.svg',
    dialCodes: ['+98'],
    slug: 'iran'
  },
  {
    name: 'Iraq',
    label: 'Iraq',
    value: 'IQ',
    code: 'IQ',
    emoji: '🇮🇶',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/iq.svg',
    dialCodes: ['+964'],
    slug: 'iraq'
  },
  {
    name: 'Ireland',
    label: 'Ireland',
    value: 'IE',
    code: 'IE',
    emoji: '🇮🇪',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ie.svg',
    dialCodes: ['+353'],
    slug: 'ireland'
  },
  {
    name: 'Isle of Man',
    label: 'Isle of Man',
    value: 'IM',
    code: 'IM',
    emoji: '🇮🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/im.svg',
    dialCodes: ['+44'],
    slug: 'isle-of-man'
  },
  {
    name: 'Israel',
    label: 'Israel',
    value: 'IL',
    code: 'IL',
    emoji: '🇮🇱',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/il.svg',
    dialCodes: ['+972'],
    slug: 'israel'
  },
  {
    name: 'Italy',
    label: 'Italy',
    value: 'IT',
    code: 'IT',
    emoji: '🇮🇹',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/it.svg',
    dialCodes: ['+39'],
    slug: 'italy'
  },
  {
    name: 'Jamaica',
    label: 'Jamaica',
    value: 'JM',
    code: 'JM',
    emoji: '🇯🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/jm.svg',
    dialCodes: ['+1 876'],
    slug: 'jamaica'
  },
  {
    name: 'Japan',
    label: 'Japan',
    value: 'JP',
    code: 'JP',
    emoji: '🇯🇵',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/jp.svg',
    dialCodes: ['+81'],
    slug: 'japan'
  },
  {
    name: 'Jersey',
    label: 'Jersey',
    value: 'JE',
    code: 'JE',
    emoji: '🇯🇪',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/je.svg',
    dialCodes: ['+44'],
    slug: 'jersey'
  },
  {
    name: 'Jordan',
    label: 'Jordan',
    value: 'JO',
    code: 'JO',
    emoji: '🇯🇴',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/jo.svg',
    dialCodes: ['+962'],
    slug: 'jordan'
  },
  {
    name: 'Kazakhstan',
    label: 'Kazakhstan',
    value: 'KZ',
    code: 'KZ',
    emoji: '🇰🇿',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/kz.svg',
    dialCodes: ['+7'],
    slug: 'kazakhstan'
  },
  {
    name: 'Kenya',
    label: 'Kenya',
    value: 'KE',
    code: 'KE',
    emoji: '🇰🇪',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ke.svg',
    dialCodes: ['+254'],
    slug: 'kenya'
  },
  {
    name: 'Kiribati',
    label: 'Kiribati',
    value: 'KI',
    code: 'KI',
    emoji: '🇰🇮',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ki.svg',
    dialCodes: ['+686'],
    slug: 'kiribati'
  },
  {
    name: 'Kosovo',
    label: 'Kosovo',
    value: 'XK',
    code: 'XK',
    emoji: '🇽🇰',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/xk.svg',
    dialCodes: ['+383'],
    slug: 'kosovo'
  },
  {
    name: 'Kuwait',
    label: 'Kuwait',
    value: 'KW',
    code: 'KW',
    emoji: '🇰🇼',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/kw.svg',
    dialCodes: ['+965'],
    slug: 'kuwait'
  },
  {
    name: 'Kyrgyzstan',
    label: 'Kyrgyzstan',
    value: 'KG',
    code: 'KG',
    emoji: '🇰🇬',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/kg.svg',
    dialCodes: ['+996'],
    slug: 'kyrgyzstan'
  },
  {
    name: 'Laos',
    label: 'Laos',
    value: 'LA',
    code: 'LA',
    emoji: '🇱🇦',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/la.svg',
    dialCodes: ['+856'],
    slug: 'laos'
  },
  {
    name: 'Latvia',
    label: 'Latvia',
    value: 'LV',
    code: 'LV',
    emoji: '🇱🇻',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/lv.svg',
    dialCodes: ['+371'],
    slug: 'latvia'
  },
  {
    name: 'Lebanon',
    label: 'Lebanon',
    value: 'LB',
    code: 'LB',
    emoji: '🇱🇧',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/lb.svg',
    dialCodes: ['+961'],
    slug: 'lebanon'
  },
  {
    name: 'Lesotho',
    label: 'Lesotho',
    value: 'LS',
    code: 'LS',
    emoji: '🇱🇸',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ls.svg',
    dialCodes: ['+266'],
    slug: 'lesotho'
  },
  {
    name: 'Liberia',
    label: 'Liberia',
    value: 'LR',
    code: 'LR',
    emoji: '🇱🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/lr.svg',
    dialCodes: ['+231'],
    slug: 'liberia'
  },
  {
    name: 'Libya',
    label: 'Libya',
    value: 'LY',
    code: 'LY',
    emoji: '🇱🇾',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ly.svg',
    dialCodes: ['+218'],
    slug: 'libya'
  },
  {
    name: 'Liechtenstein',
    label: 'Liechtenstein',
    value: 'LI',
    code: 'LI',
    emoji: '🇱🇮',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/li.svg',
    dialCodes: ['+423'],
    slug: 'liechtenstein'
  },
  {
    name: 'Lithuania',
    label: 'Lithuania',
    value: 'LT',
    code: 'LT',
    emoji: '🇱🇹',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/lt.svg',
    dialCodes: ['+370'],
    slug: 'lithuania'
  },
  {
    name: 'Luxembourg',
    label: 'Luxembourg',
    value: 'LU',
    code: 'LU',
    emoji: '🇱🇺',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/lu.svg',
    dialCodes: ['+352'],
    slug: 'luxembourg'
  },
  {
    name: 'Macao SAR China',
    label: 'Macao SAR China',
    value: 'MO',
    code: 'MO',
    emoji: '🇲🇴',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mo.svg',
    dialCodes: ['+853'],
    slug: 'macao-sar-china'
  },
  {
    name: 'Madagascar',
    label: 'Madagascar',
    value: 'MG',
    code: 'MG',
    emoji: '🇲🇬',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mg.svg',
    dialCodes: ['+261'],
    slug: 'madagascar'
  },
  {
    name: 'Malawi',
    label: 'Malawi',
    value: 'MW',
    code: 'MW',
    emoji: '🇲🇼',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mw.svg',
    dialCodes: ['+265'],
    slug: 'malawi'
  },
  {
    name: 'Malaysia',
    label: 'Malaysia',
    value: 'MY',
    code: 'MY',
    emoji: '🇲🇾',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/my.svg',
    dialCodes: ['+60'],
    slug: 'malaysia'
  },
  {
    name: 'Maldives',
    label: 'Maldives',
    value: 'MV',
    code: 'MV',
    emoji: '🇲🇻',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mv.svg',
    dialCodes: ['+960'],
    slug: 'maldives'
  },
  {
    name: 'Mali',
    label: 'Mali',
    value: 'ML',
    code: 'ML',
    emoji: '🇲🇱',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ml.svg',
    dialCodes: ['+223'],
    slug: 'mali'
  },
  {
    name: 'Malta',
    label: 'Malta',
    value: 'MT',
    code: 'MT',
    emoji: '🇲🇹',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mt.svg',
    dialCodes: ['+356'],
    slug: 'malta'
  },
  {
    name: 'Marshall Islands',
    label: 'Marshall Islands',
    value: 'MH',
    code: 'MH',
    emoji: '🇲🇭',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mh.svg',
    dialCodes: ['+692'],
    slug: 'marshall-islands'
  },
  {
    name: 'Martinique',
    label: 'Martinique',
    value: 'MQ',
    code: 'MQ',
    emoji: '🇲🇶',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mq.svg',
    dialCodes: ['+596'],
    slug: 'martinique'
  },
  {
    name: 'Mauritania',
    label: 'Mauritania',
    value: 'MR',
    code: 'MR',
    emoji: '🇲🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mr.svg',
    dialCodes: ['+222'],
    slug: 'mauritania'
  },
  {
    name: 'Mauritius',
    label: 'Mauritius',
    value: 'MU',
    code: 'MU',
    emoji: '🇲🇺',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mu.svg',
    dialCodes: ['+230'],
    slug: 'mauritius'
  },
  {
    name: 'Mayotte',
    label: 'Mayotte',
    value: 'YT',
    code: 'YT',
    emoji: '🇾🇹',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/yt.svg',
    dialCodes: ['+262'],
    slug: 'mayotte'
  },
  {
    name: 'Mexico',
    label: 'Mexico',
    value: 'MX',
    code: 'MX',
    emoji: '🇲🇽',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mx.svg',
    dialCodes: ['+52'],
    slug: 'mexico'
  },
  {
    name: 'Micronesia',
    label: 'Micronesia',
    value: 'FM',
    code: 'FM',
    emoji: '🇫🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/fm.svg',
    dialCodes: ['+691'],
    slug: 'micronesia'
  },
  {
    name: 'Moldova',
    label: 'Moldova',
    value: 'MD',
    code: 'MD',
    emoji: '🇲🇩',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/md.svg',
    dialCodes: ['+373'],
    slug: 'moldova'
  },
  {
    name: 'Monaco',
    label: 'Monaco',
    value: 'MC',
    code: 'MC',
    emoji: '🇲🇨',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mc.svg',
    dialCodes: ['+377'],
    slug: 'monaco'
  },
  {
    name: 'Mongolia',
    label: 'Mongolia',
    value: 'MN',
    code: 'MN',
    emoji: '🇲🇳',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mn.svg',
    dialCodes: ['+976'],
    slug: 'mongolia'
  },
  {
    name: 'Montenegro',
    label: 'Montenegro',
    value: 'ME',
    code: 'ME',
    emoji: '🇲🇪',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/me.svg',
    dialCodes: ['+382'],
    slug: 'montenegro'
  },
  {
    name: 'Montserrat',
    label: 'Montserrat',
    value: 'MS',
    code: 'MS',
    emoji: '🇲🇸',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ms.svg',
    dialCodes: ['+1 664'],
    slug: 'montserrat'
  },
  {
    name: 'Morocco',
    label: 'Morocco',
    value: 'MA',
    code: 'MA',
    emoji: '🇲🇦',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ma.svg',
    dialCodes: ['+212'],
    slug: 'morocco'
  },
  {
    name: 'Mozambique',
    label: 'Mozambique',
    value: 'MZ',
    code: 'MZ',
    emoji: '🇲🇿',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mz.svg',
    dialCodes: ['+258'],
    slug: 'mozambique'
  },
  {
    name: 'Myanmar (Burma)',
    label: 'Myanmar (Burma)',
    value: 'MM',
    code: 'MM',
    emoji: '🇲🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mm.svg',
    dialCodes: ['+95'],
    slug: 'myanmar-(burma)'
  },
  {
    name: 'Namibia',
    label: 'Namibia',
    value: 'NA',
    code: 'NA',
    emoji: '🇳🇦',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/na.svg',
    dialCodes: ['+264'],
    slug: 'namibia'
  },
  {
    name: 'Nauru',
    label: 'Nauru',
    value: 'NR',
    code: 'NR',
    emoji: '🇳🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/nr.svg',
    dialCodes: ['+674'],
    slug: 'nauru'
  },
  {
    name: 'Nepal',
    label: 'Nepal',
    value: 'NP',
    code: 'NP',
    emoji: '🇳🇵',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/np.svg',
    dialCodes: ['+977'],
    slug: 'nepal'
  },
  {
    name: 'Netherlands',
    label: 'Netherlands',
    value: 'NL',
    code: 'NL',
    emoji: '🇳🇱',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/nl.svg',
    dialCodes: ['+31'],
    slug: 'netherlands'
  },
  {
    name: 'New Caledonia',
    label: 'New Caledonia',
    value: 'NC',
    code: 'NC',
    emoji: '🇳🇨',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/nc.svg',
    dialCodes: ['+687'],
    slug: 'new-caledonia'
  },
  {
    name: 'New Zealand',
    label: 'New Zealand',
    value: 'NZ',
    code: 'NZ',
    emoji: '🇳🇿',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/nz.svg',
    dialCodes: ['+64'],
    slug: 'new-zealand'
  },
  {
    name: 'Nicaragua',
    label: 'Nicaragua',
    value: 'NI',
    code: 'NI',
    emoji: '🇳🇮',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ni.svg',
    dialCodes: ['+505'],
    slug: 'nicaragua'
  },
  {
    name: 'Niger',
    label: 'Niger',
    value: 'NE',
    code: 'NE',
    emoji: '🇳🇪',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ne.svg',
    dialCodes: ['+227'],
    slug: 'niger'
  },
  {
    name: 'Nigeria',
    label: 'Nigeria',
    value: 'NG',
    code: 'NG',
    emoji: '🇳🇬',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ng.svg',
    dialCodes: ['+234'],
    slug: 'nigeria'
  },
  {
    name: 'Niue',
    label: 'Niue',
    value: 'NU',
    code: 'NU',
    emoji: '🇳🇺',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/nu.svg',
    dialCodes: ['+683'],
    slug: 'niue'
  },
  {
    name: 'Norfolk Island',
    label: 'Norfolk Island',
    value: 'NF',
    code: 'NF',
    emoji: '🇳🇫',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/nf.svg',
    dialCodes: ['+672'],
    slug: 'norfolk-island'
  },
  {
    name: 'North Korea',
    label: 'North Korea',
    value: 'KP',
    code: 'KP',
    emoji: '🇰🇵',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/kp.svg',
    dialCodes: ['+850'],
    slug: 'north-korea'
  },
  {
    name: 'Northern Mariana Islands',
    label: 'Northern Mariana Islands',
    value: 'MP',
    code: 'MP',
    emoji: '🇲🇵',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mp.svg',
    dialCodes: ['+1 670'],
    slug: 'northern-mariana-islands'
  },
  {
    name: 'Norway',
    label: 'Norway',
    value: 'NO',
    code: 'NO',
    emoji: '🇳🇴',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/no.svg',
    dialCodes: ['+47'],
    slug: 'norway'
  },
  {
    name: 'Oman',
    label: 'Oman',
    value: 'OM',
    code: 'OM',
    emoji: '🇴🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/om.svg',
    dialCodes: ['+968'],
    slug: 'oman'
  },
  {
    name: 'Pakistan',
    label: 'Pakistan',
    value: 'PK',
    code: 'PK',
    emoji: '🇵🇰',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pk.svg',
    dialCodes: ['+92'],
    slug: 'pakistan'
  },
  {
    name: 'Palau',
    label: 'Palau',
    value: 'PW',
    code: 'PW',
    emoji: '🇵🇼',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pw.svg',
    dialCodes: ['+680'],
    slug: 'palau'
  },
  {
    name: 'Palestinian Territories',
    label: 'Palestinian Territories',
    value: 'PS',
    code: 'PS',
    emoji: '🇵🇸',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ps.svg',
    dialCodes: ['+970'],
    slug: 'palestinian-territories'
  },
  {
    name: 'Panama',
    label: 'Panama',
    value: 'PA',
    code: 'PA',
    emoji: '🇵🇦',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pa.svg',
    dialCodes: ['+507'],
    slug: 'panama'
  },
  {
    name: 'Papua New Guinea',
    label: 'Papua New Guinea',
    value: 'PG',
    code: 'PG',
    emoji: '🇵🇬',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pg.svg',
    dialCodes: ['+675'],
    slug: 'papua-new-guinea'
  },
  {
    name: 'Paraguay',
    label: 'Paraguay',
    value: 'PY',
    code: 'PY',
    emoji: '🇵🇾',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/py.svg',
    dialCodes: ['+595'],
    slug: 'paraguay'
  },
  {
    name: 'Peru',
    label: 'Peru',
    value: 'PE',
    code: 'PE',
    emoji: '🇵🇪',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pe.svg',
    dialCodes: ['+51'],
    slug: 'peru'
  },
  {
    name: 'Philippines',
    label: 'Philippines',
    value: 'PH',
    code: 'PH',
    emoji: '🇵🇭',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ph.svg',
    dialCodes: ['+63'],
    slug: 'philippines'
  },
  {
    name: 'Pitcairn Islands',
    label: 'Pitcairn Islands',
    value: 'PN',
    code: 'PN',
    emoji: '🇵🇳',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pn.svg',
    dialCodes: ['+64'],
    slug: 'pitcairn-islands'
  },
  {
    name: 'Poland',
    label: 'Poland',
    value: 'PL',
    code: 'PL',
    emoji: '🇵🇱',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pl.svg',
    dialCodes: ['+48'],
    slug: 'poland'
  },
  {
    name: 'Portugal',
    label: 'Portugal',
    value: 'PT',
    code: 'PT',
    emoji: '🇵🇹',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pt.svg',
    dialCodes: ['+351'],
    slug: 'portugal'
  },
  {
    name: 'Puerto Rico',
    label: 'Puerto Rico',
    value: 'PR',
    code: 'PR',
    emoji: '🇵🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pr.svg',
    dialCodes: ['+1 787'],
    slug: 'puerto-rico'
  },
  {
    name: 'Qatar',
    label: 'Qatar',
    value: 'QA',
    code: 'QA',
    emoji: '🇶🇦',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/qa.svg',
    dialCodes: ['+974'],
    slug: 'qatar'
  },
  {
    name: 'Réunion',
    label: 'Réunion',
    value: 'RE',
    code: 'RE',
    emoji: '🇷🇪',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/re.svg',
    dialCodes: ['+262'],
    slug: 'reunion'
  },
  {
    name: 'Romania',
    label: 'Romania',
    value: 'RO',
    code: 'RO',
    emoji: '🇷🇴',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ro.svg',
    dialCodes: ['+40'],
    slug: 'romania'
  },
  {
    name: 'Russia',
    label: 'Russia',
    value: 'RU',
    code: 'RU',
    emoji: '🇷🇺',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ru.svg',
    dialCodes: ['+7'],
    slug: 'russia'
  },
  {
    name: 'Rwanda',
    label: 'Rwanda',
    value: 'RW',
    code: 'RW',
    emoji: '🇷🇼',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/rw.svg',
    dialCodes: ['+250'],
    slug: 'rwanda'
  },
  {
    name: 'Saint Barthélemy',
    label: 'Saint Barthélemy',
    value: 'BL',
    code: 'BL',
    emoji: '🇧🇱',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/bl.svg',
    slug: 'saint-barthelemy'
  },
  {
    name: 'Saint Helena',
    label: 'Saint Helena',
    value: 'SH',
    code: 'SH',
    emoji: '🇸🇭',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sh.svg',
    dialCodes: ['+290'],
    slug: 'saint-helena'
  },
  {
    name: 'Saint Kitts & Nevis',
    label: 'Saint Kitts & Nevis',
    value: 'KN',
    code: 'KN',
    emoji: '🇰🇳',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/kn.svg',
    dialCodes: ['+1 869'],
    slug: 'saint-kitts-and-nevis'
  },
  {
    name: 'Saint Lucia',
    label: 'Saint Lucia',
    value: 'LC',
    code: 'LC',
    emoji: '🇱🇨',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/lc.svg',
    dialCodes: ['+1 758'],
    slug: 'saint-lucia'
  },
  {
    name: 'Saint Martin',
    label: 'Saint Martin',
    value: 'MF',
    code: 'MF',
    emoji: '🇲🇫',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/mf.svg',
    slug: 'saint-martin'
  },
  {
    name: 'Saint Pierre & Miquelon',
    label: 'Saint Pierre & Miquelon',
    value: 'PM',
    code: 'PM',
    emoji: '🇵🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/pm.svg',
    dialCodes: ['+508'],
    slug: 'saint-pierre-and-miquelon'
  },
  {
    name: 'Samoa',
    label: 'Samoa',
    value: 'WS',
    code: 'WS',
    emoji: '🇼🇸',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ws.svg',
    dialCodes: ['+685'],
    slug: 'samoa'
  },
  {
    name: 'San Marino',
    label: 'San Marino',
    value: 'SM',
    code: 'SM',
    emoji: '🇸🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sm.svg',
    dialCodes: ['+378'],
    slug: 'san-marino'
  },
  {
    name: 'Sao Tome & Principe',
    label: 'Sao Tome & Principe',
    value: 'ST',
    code: 'ST',
    emoji: '🇸🇹',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/st.svg',
    dialCodes: ['+239'],
    slug: 'sao-tome-and-principe'
  },
  {
    name: 'Saudi Arabia',
    label: 'Saudi Arabia',
    value: 'SA',
    code: 'SA',
    emoji: '🇸🇦',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sa.svg',
    dialCodes: ['+966'],
    slug: 'saudi-arabia'
  },
  {
    name: 'Senegal',
    label: 'Senegal',
    value: 'SN',
    code: 'SN',
    emoji: '🇸🇳',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sn.svg',
    dialCodes: ['+221'],
    slug: 'senegal'
  },
  {
    name: 'Serbia',
    label: 'Serbia',
    value: 'RS',
    code: 'RS',
    emoji: '🇷🇸',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/rs.svg',
    dialCodes: ['+381'],
    slug: 'serbia'
  },
  {
    name: 'Seychelles',
    label: 'Seychelles',
    value: 'SC',
    code: 'SC',
    emoji: '🇸🇨',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sc.svg',
    dialCodes: ['+248'],
    slug: 'seychelles'
  },
  {
    name: 'Sierra Leone',
    label: 'Sierra Leone',
    value: 'SL',
    code: 'SL',
    emoji: '🇸🇱',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sl.svg',
    dialCodes: ['+232'],
    slug: 'sierra-leone'
  },
  {
    name: 'Singapore',
    label: 'Singapore',
    value: 'SG',
    code: 'SG',
    emoji: '🇸🇬',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sg.svg',
    dialCodes: ['+65'],
    slug: 'singapore'
  },
  {
    name: 'Sint Maarten',
    label: 'Sint Maarten',
    value: 'SX',
    code: 'SX',
    emoji: '🇸🇽',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sx.svg',
    dialCodes: ['+1 721'],
    slug: 'sint-maarten'
  },
  {
    name: 'Slovakia',
    label: 'Slovakia',
    value: 'SK',
    code: 'SK',
    emoji: '🇸🇰',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sk.svg',
    dialCodes: ['+421'],
    slug: 'slovakia'
  },
  {
    name: 'Slovenia',
    label: 'Slovenia',
    value: 'SI',
    code: 'SI',
    emoji: '🇸🇮',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/si.svg',
    dialCodes: ['+386'],
    slug: 'slovenia'
  },
  {
    name: 'Solomon Islands',
    label: 'Solomon Islands',
    value: 'SB',
    code: 'SB',
    emoji: '🇸🇧',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sb.svg',
    dialCodes: ['+677'],
    slug: 'solomon-islands'
  },
  {
    name: 'Somalia',
    label: 'Somalia',
    value: 'SO',
    code: 'SO',
    emoji: '🇸🇴',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/so.svg',
    dialCodes: ['+252'],
    slug: 'somalia'
  },
  {
    name: 'South Africa',
    label: 'South Africa',
    value: 'ZA',
    code: 'ZA',
    emoji: '🇿🇦',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/za.svg',
    dialCodes: ['+27'],
    slug: 'south-africa'
  },
  {
    name: 'South Georgia & South Sandwich Islands',
    label: 'South Georgia & South Sandwich Islands',
    value: 'GS',
    code: 'GS',
    emoji: '🇬🇸',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gs.svg',
    slug: 'south-georgia-and-south-sandwich-islands'
  },
  {
    name: 'South Korea',
    label: 'South Korea',
    value: 'KR',
    code: 'KR',
    emoji: '🇰🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/kr.svg',
    dialCodes: ['+82'],
    slug: 'south-korea'
  },
  {
    name: 'South Sudan',
    label: 'South Sudan',
    value: 'SS',
    code: 'SS',
    emoji: '🇸🇸',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ss.svg',
    dialCodes: ['+211'],
    slug: 'south-sudan'
  },
  {
    name: 'Spain',
    label: 'Spain',
    value: 'ES',
    code: 'ES',
    emoji: '🇪🇸',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/es.svg',
    dialCodes: ['+34'],
    slug: 'spain'
  },
  {
    name: 'Sri Lanka',
    label: 'Sri Lanka',
    value: 'LK',
    code: 'LK',
    emoji: '🇱🇰',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/lk.svg',
    dialCodes: ['+94'],
    slug: 'sri-lanka'
  },
  {
    name: 'Sudan',
    label: 'Sudan',
    value: 'SD',
    code: 'SD',
    emoji: '🇸🇩',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sd.svg',
    dialCodes: ['+249'],
    slug: 'sudan'
  },
  {
    name: 'Suriname',
    label: 'Suriname',
    value: 'SR',
    code: 'SR',
    emoji: '🇸🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sr.svg',
    dialCodes: ['+597'],
    slug: 'suriname'
  },
  {
    name: 'Svalbard & Jan Mayen',
    label: 'Svalbard & Jan Mayen',
    value: 'SJ',
    code: 'SJ',
    emoji: '🇸🇯',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sj.svg',
    slug: 'svalbard-and-jan-mayen'
  },
  {
    name: 'Sweden',
    label: 'Sweden',
    value: 'SE',
    code: 'SE',
    emoji: '🇸🇪',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/se.svg',
    dialCodes: ['+46'],
    slug: 'sweden'
  },
  {
    name: 'Switzerland',
    label: 'Switzerland',
    value: 'CH',
    code: 'CH',
    emoji: '🇨🇭',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ch.svg',
    dialCodes: ['+41'],
    slug: 'switzerland'
  },
  {
    name: 'Syria',
    label: 'Syria',
    value: 'SY',
    code: 'SY',
    emoji: '🇸🇾',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/sy.svg',
    dialCodes: ['+963'],
    slug: 'syria'
  },
  {
    name: 'Taiwan',
    label: 'Taiwan',
    value: 'TW',
    code: 'TW',
    emoji: '🇹🇼',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tw.svg',
    dialCodes: ['+886'],
    slug: 'taiwan'
  },
  {
    name: 'Tajikistan',
    label: 'Tajikistan',
    value: 'TJ',
    code: 'TJ',
    emoji: '🇹🇯',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tj.svg',
    dialCodes: ['+992'],
    slug: 'tajikistan'
  },
  {
    name: 'Tanzania',
    label: 'Tanzania',
    value: 'TZ',
    code: 'TZ',
    emoji: '🇹🇿',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tz.svg',
    dialCodes: ['+255'],
    slug: 'tanzania'
  },
  {
    name: 'Thailand',
    label: 'Thailand',
    value: 'TH',
    code: 'TH',
    emoji: '🇹🇭',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/th.svg',
    dialCodes: ['+66'],
    slug: 'thailand'
  },
  {
    name: 'Timor-Leste',
    label: 'Timor-Leste',
    value: 'TL',
    code: 'TL',
    emoji: '🇹🇱',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tl.svg',
    dialCodes: ['+670'],
    slug: 'timor-leste'
  },
  {
    name: 'Togo',
    label: 'Togo',
    value: 'TG',
    code: 'TG',
    emoji: '🇹🇬',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tg.svg',
    dialCodes: ['+228'],
    slug: 'togo'
  },
  {
    name: 'Tokelau',
    label: 'Tokelau',
    value: 'TK',
    code: 'TK',
    emoji: '🇹🇰',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tk.svg',
    dialCodes: ['+690'],
    slug: 'tokelau'
  },
  {
    name: 'Tonga',
    label: 'Tonga',
    value: 'TO',
    code: 'TO',
    emoji: '🇹🇴',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/to.svg',
    dialCodes: ['+676'],
    slug: 'tonga'
  },
  {
    name: 'Trinidad & Tobago',
    label: 'Trinidad & Tobago',
    value: 'TT',
    code: 'TT',
    emoji: '🇹🇹',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tt.svg',
    dialCodes: ['+1 868'],
    slug: 'trinidad-and-tobago'
  },
  {
    name: 'Tunisia',
    label: 'Tunisia',
    value: 'TN',
    code: 'TN',
    emoji: '🇹🇳',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tn.svg',
    dialCodes: ['+216'],
    slug: 'tunisia'
  },
  {
    name: 'Turkey',
    label: 'Turkey',
    value: 'TR',
    code: 'TR',
    emoji: '🇹🇷',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tr.svg',
    dialCodes: ['+90'],
    slug: 'turkey'
  },
  {
    name: 'Turkmenistan',
    label: 'Turkmenistan',
    value: 'TM',
    code: 'TM',
    emoji: '🇹🇲',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tm.svg',
    dialCodes: ['+993'],
    slug: 'turkmenistan'
  },
  {
    name: 'Turks & Caicos Islands',
    label: 'Turks & Caicos Islands',
    value: 'TC',
    code: 'TC',
    emoji: '🇹🇨',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tc.svg',
    dialCodes: ['+1 649'],
    slug: 'turks-and-caicos-islands'
  },
  {
    name: 'Tuvalu',
    label: 'Tuvalu',
    value: 'TV',
    code: 'TV',
    emoji: '🇹🇻',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/tv.svg',
    dialCodes: ['+688'],
    slug: 'tuvalu'
  },
  {
    name: 'Uganda',
    label: 'Uganda',
    value: 'UG',
    code: 'UG',
    emoji: '🇺🇬',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ug.svg',
    dialCodes: ['+256'],
    slug: 'uganda'
  },
  {
    name: 'Ukraine',
    label: 'Ukraine',
    value: 'UA',
    code: 'UA',
    emoji: '🇺🇦',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ua.svg',
    dialCodes: ['+380'],
    slug: 'ukraine'
  },
  {
    name: 'United Arab Emirates',
    label: 'United Arab Emirates',
    value: 'AE',
    code: 'AE',
    emoji: '🇦🇪',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ae.svg',
    dialCodes: ['+971'],
    slug: 'united-arab-emirates'
  },
  {
    name: 'United Kingdom',
    label: 'United Kingdom',
    value: 'GB',
    code: 'GB',
    emoji: '🇬🇧',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/gb.svg',
    dialCodes: ['+44'],
    slug: 'united-kingdom'
  },
  {
    name: 'United States',
    label: 'United States',
    value: 'US',
    code: 'US',
    emoji: '🇺🇸',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/us.svg',
    dialCodes: ['+1'],
    slug: 'united-states'
  },
  {
    name: 'Uruguay',
    label: 'Uruguay',
    value: 'UY',
    code: 'UY',
    emoji: '🇺🇾',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/uy.svg',
    dialCodes: ['+598'],
    slug: 'uruguay'
  },
  {
    name: 'Uzbekistan',
    label: 'Uzbekistan',
    value: 'UZ',
    code: 'UZ',
    emoji: '🇺🇿',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/uz.svg',
    dialCodes: ['+998'],
    slug: 'uzbekistan'
  },
  {
    name: 'Vanuatu',
    label: 'Vanuatu',
    value: 'VU',
    code: 'VU',
    emoji: '🇻🇺',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/vu.svg',
    dialCodes: ['+678'],
    slug: 'vanuatu'
  },
  {
    name: 'Vatican City',
    label: 'Vatican City',
    value: 'VA',
    code: 'VA',
    emoji: '🇻🇦',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/va.svg',
    dialCodes: ['+379'],
    slug: 'vatican-city'
  },
  {
    name: 'Venezuela',
    label: 'Venezuela',
    value: 'VE',
    code: 'VE',
    emoji: '🇻🇪',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/ve.svg',
    dialCodes: ['+58'],
    slug: 'venezuela'
  },
  {
    name: 'Vietnam',
    label: 'Vietnam',
    value: 'VN',
    code: 'VN',
    emoji: '🇻🇳',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/vn.svg',
    dialCodes: ['+84'],
    slug: 'vietnam'
  },
  {
    name: 'Wallis & Futuna',
    label: 'Wallis & Futuna',
    value: 'WF',
    code: 'WF',
    emoji: '🇼🇫',
    image: 'https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.2.3/flags/4x3/wf.svg',
    dialCodes: ['+681'],
    slug: 'wallis-and-futuna'
  }
];
